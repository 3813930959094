import React from 'react';
import { browserName, isMobile } from 'react-device-detect';
import fileDownload from 'js-file-download';
import axios from 'axios';

export const Download = ({ finalImage, mutantId, stampColor }) => {
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => response.data)
      .then((blob) => {
        if (isMobile) {
          const currentBlob = new Blob([blob], { type: 'image/png' });
          const imageUrl = URL.createObjectURL(currentBlob);

          if (!window.open(imageUrl, '_blank')) {
            window.location.href = imageUrl;
          }
        } else
          fileDownload(blob, filename, blob.type);

        if (browserName === 'Safari' || browserName === 'Mobile Safari')
          return;

        document.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <div>
      <div className='fetch d-flex align-items-center justify-content-center gap-4'>
        <button className='active btn w-100 py-3' onClick={() => {
          if (mutantId === '')
            handleDownload(finalImage, `stamped-${stampColor}_${new Date().toISOString()}.png`);
          else
            handleDownload(finalImage, `${mutantId}-stamped-${stampColor}.png`);
        }} > <img src='/assets/download.png' alt='thumb' className='mx-2' />Save</button>
      </div>
    </div>
  )
}
