import React from 'react';
import { Main } from './page/Main';

const App = () => {
  return (
    <div className = 'App'> 
      <Main />
    </div>
  )
}

export default App;