import React, { useState } from "react";
import axios from "axios";
import { Download } from "./Download";
import { Svg } from "./Svg";

export const MainBody = () => {
  const [pledge, setPledge] = useState(false);
  const [mutantId, setMutantId] = useState("");
  const [mutantImageUrl, setMutantImageUrl] = useState("");
  const [stampColor, setColor] = useState("");
  const [finalImage, setFinalImage] = useState("");
  const [isMerging, setMerging] = useState(false);

  const isFormValid = () => {
    return (
      mutantImageUrl !== "" &&
      mutantImageUrl.thumbnail !== "" &&
      !mutantImageUrl.thumbnail.includes("no-mutant") &&
      stampColor !== "" &&
      pledge === true
    );
  };

  const getImage = async (id) => {
    if (id !== "") {
      axios
        .get(
          `https://api.opensea.io/api/v1/asset/0x60e4d786628fea6478f785a6d7e704777c86a7c6/${id}/`,
          { validateStatus: () => true }
        )
        .then(function (response) {
          console.log(response.data);
          if (response.status === 200 && response.data) {
            setMutantImageUrl({
              thumbnail: response.data.image_preview_url,
              original: response.data.image_original_url,
            });
          } else {
            setColor("");
            setPledge(false);
            setMutantImageUrl({ thumbnail: "/assets/no-mutant.png" });
          }
        })
        .catch(function (error) {
          console.error(error);
          setColor("");
          setPledge(false);
          setMutantImageUrl({ thumbnail: "/assets/no-mutant.png" });
        });
    } else {
      setColor("");
      setPledge(false);
      setMutantImageUrl("");
    }
  };

  async function loadImgURL(cid) {
    if (cid === "" || cid === null || cid === undefined) {
      return;
    }

    return `https://houndpit.infura-ipfs.io/ipfs/${cid}`;
  }

  const merge = async () => {
    setMerging(true);

    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    ctx.fillRect(0, 0, 1262, 1262);

    const imageObj1 = new Image(1262, 1262);
    const imageObj2 = new Image(1262, 1262);

    console.log(mutantImageUrl);
    let url = mutantImageUrl.original;
    if (mutantImageUrl.original.includes("ipfs"))
      url = await loadImgURL(mutantImageUrl.original.split("/").pop());

    imageObj1.src = url;
    imageObj1.crossOrigin = "anonymous";
    imageObj1.onload = function () {
      if (stampColor === "black")
        imageObj2.src = `https://i.ibb.co/bR7cz2f/cartel-drip-black.png`;
      else if (stampColor === "white")
        imageObj2.src = "https://i.ibb.co/d26F9vq/cartel-drip-white.png";
      else imageObj2.src = "https://i.ibb.co/cXNpZbJ/cartel-drip-orange.png";

      imageObj2.crossOrigin = "anonymous";
      imageObj2.onload = function () {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(imageObj1, 0, 0, 1262, 1262);
        ctx.drawImage(imageObj2, 0, 0, 1262, 1262);
        ctx.globalCompositeOperation = "destination-in";

        const img = canvas.toDataURL("image/png");

        setMerging(false);
        setFinalImage(img);
      };
    };
  };

  return (
    <div>
      <div className="content mx-5">
        <div className="col-12 col-lg-8 mx-auto text-center">
          <h2 className="display-5 fw-bold text-white mt-2 mb-3">
            Welcome to the Cartel
          </h2>
          <p className="lead text-white mb-4">
            Pledge your loyalty to the{" "}
            <a
              href="https://www.mutantdao.com"
              target="_blank"
              rel="noreferrer"
            >
              <b>Mutant Cartel</b>
            </a>{" "}
            by stamping your Mutant or Ape.
          </p>
        </div>
        <div className="d-flex align-items-center mb-2 colors">
          <b
            className=""
            onClick={() => {
              document.location.reload();
            }}
          >
            Reset
          </b>
        </div>
        <div className="section py-4 row px-3 mb-4">
          <div className="align-self-center order-1">
            <div className="d-flex flex-row align-items-center mb-3 colors">
              <b className="flex-fill lead text-white justify-content-left">
                Step 1: Enter your Mutant ID
              </b>
            </div>
            <div className="d-flex align-items-center justify-content-left">
              <input
                type="number"
                placeholder="Mutant ID"
                className="flex-fill py-3 px-0 text-center"
                value={mutantId || ""}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setColor("");
                    setPledge(false);
                    setMutantImageUrl("");
                  }

                  setMutantId(e.target.value);
                }}
                onBlur={(e) => getImage(mutantId)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-left mt-3 colors">
              <b className="lead text-white">
                Optional: Upload your Ape or Custom Mutant
              </b>
            </div>
            <div className="colors">
              <p>
                Have an Ape or custom Mutant? Or customize on{" "}
                <a
                  href="https://niftytailor.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  NiftyTailor
                </a>{" "}
                first and upload below.
              </p>
            </div>
            <div className="fetch d-flex align-items-center justify-content-left">
              <label htmlFor="img-input" className="flex-fill py-3 text-center">
                Upload Custom
              </label>
              <input
                type="file"
                id="img-input"
                name="myImage"
                style={{ display: "none" }}
                accept="image/*"
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={(event) => {
                  setMutantId("");
                  setMutantImageUrl({
                    thumbnail: URL.createObjectURL(event.target.files[0]),
                    original: URL.createObjectURL(event.target.files[0]),
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-left gap-3 my-3 colors">
              <b className="lead text-white">Step 2: Choose your Color</b>
            </div>
            <div className="d-flex align-items-center justify-content-left gap-3 my-3 colors">
              <input
                checked={stampColor === "orange"}
                onChange={(e) => setColor(e.target.value)}
                type="radio"
                value="orange"
              />{" "}
              Orange
              <input
                checked={stampColor === "black"}
                onChange={(e) => setColor(e.target.value)}
                type="radio"
                value="black"
              />{" "}
              Black
              <input
                checked={stampColor === "white"}
                onChange={(e) => setColor(e.target.value)}
                type="radio"
                value="white"
              />{" "}
              White
            </div>
            <div className="d-flex align-items-center justify-content-left gap-3 my-3 colors">
              <b className="lead text-white">Step 3: Take the Pledge</b>
            </div>
            <div className="d-flex align-items-center justify-content-left gap-3 my-3 w-100">
              <input
                type="checkbox"
                checked={pledge}
                className="checkbox"
                onChange={() => setPledge(!pledge)}
              />
              <div className="text text-width">
                I swear by the Apes of old and by all that is sacred to Mutants
                that I stand with the Mutant Cartel.
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-left gap-3 my-3 colors">
              <b className="lead text-white">Step 4: Stamp your PFP</b>
            </div>
            {!isMerging ? (
              <div className="generate d-flex align-items-center justify-content-center mt-3">
                <button
                  className={
                    isFormValid() ? "active btn w-100 py-3" : "btn w-100 py-3"
                  }
                  onClick={merge}
                  disabled={!isFormValid() || finalImage}
                >
                  {" "}
                  <Svg /> <span className="mx-2"> Generate</span>
                </button>
              </div>
            ) : (
              <div className="generate d-flex align-items-center justify-content-center mt-3">
                <button className="btn w-100 py-3" disabled>
                  <span className="mx-2">Stamping Mutant ...</span>
                </button>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-left gap-3 mt-3 mb-1 colors">
              <b className="lead text-white">Step 5: Save</b>
            </div>
            {finalImage && (
              <div>
                <div className="colors">
                  <p>
                    Welcome to the Cartel! Wear your stamp with pride, share it
                    far and wide. It's an honor to have you among us.
                  </p>
                </div>
                <Download
                  finalImage={finalImage}
                  mutantId={mutantId}
                  stampColor={stampColor}
                />
              </div>
            )}
            <div className="media-container img-container my-3">
              <div>
                <img
                  src={
                    finalImage ||
                    mutantImageUrl.thumbnail ||
                    "/assets/placeholder.png"
                  }
                  className=""
                  alt="thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas width="1262" height="1262" id="canvas" hidden></canvas>
    </div>
  );
};
