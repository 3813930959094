import React from 'react'
import { Header } from '../components/Header'
import { MainBody } from '../components/MainBody'

export const Main = () => {
  return (
    <div>
      <Header />
      <MainBody />
    </div>
  )
}
